import { Stack, router } from 'expo-router';
import { StyleSheet } from 'react-native';

import { IconArrowBack, MqSwitch, Pressable, tokens } from '@fhs/ui';

import { getScreenTitleHeader } from '../../../../../components/get-screen-title-header';

export default function SlugLayout() {
  return (
    <Stack>
      <Stack.Screen
        name="index"
        options={{
          header: props => (
            <MqSwitch
              $ltDesktop={() => getScreenTitleHeader()(props)}
              $gteDesktop={() => <MenuItemDesktopHeader />}
            />
          ),
          title: '',
        }}
      />
      <Stack.Screen name="customize" options={{ headerShown: false }} />
    </Stack>
  );
}

const MenuItemDesktopHeader = () => {
  return (
    <Pressable
      borderRadius={100}
      borderWidth={1}
      style={styles.iconContainer}
      onPress={() => router.back()}
    >
      <IconArrowBack size={24} />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    backgroundColor: tokens.colors.$white,
    height: 48,
    width: 48,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    borderColor: tokens.colors.$black10,
    shadowColor: tokens.colors.$black,
    shadowOffset: { width: 0, height: 20 },
    shadowOpacity: 0.1,
    shadowRadius: 30,
    top: 19,
    left: 20,
  },
});
