import { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { ActionSheet, Button, FormControlTextInput, IconClose, Text, tokens } from '@fhs/ui';

type Props = {
  instructions: string | undefined | null;
  onClose(): void;
  isVisible: boolean;
};

export function OrderInstructionsActionSheet(props: Props) {
  const [instructions, setInstructions] = useState(props.instructions ?? '');

  return (
    <ActionSheet isVisible={props.isVisible} onClose={props.onClose}>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text.Heading type="four">Enter Your Order Instructions</Text.Heading>
          <Button type="ghost" onPress={props.onClose} style={styles.closeButton}>
            <Button.Icon>
              <IconClose />
            </Button.Icon>
          </Button>
        </View>

        <FormControlTextInput
          style={styles.input}
          placeholder='e.g., "Slice my sub in 3"'
          label="Order Instructions"
          multiline
          value={instructions}
          onChangeText={setInstructions}
        />

        <View style={styles.notesContainer}>
          <Text.Ui size="sm" style={styles.notes}>
            Need extra utensils? Want your sub sliced? Let us know. Or go back to customize
            ingredients on the item page.{'\n\n'}
          </Text.Ui>
          <Text.Ui size="sm" style={styles.notes}>
            Not all requests are guaranteed.
          </Text.Ui>
        </View>

        <Button
          size="lg"
          disabled={instructions === props.instructions}
          onPress={() => {
            // TODO: Implement
            props.onClose();
          }}
        >
          <Button.Text>Save Instructions</Button.Text>
        </Button>
      </View>
    </ActionSheet>
  );
}

const styles = StyleSheet.create({
  notes: {
    color: tokens.colors.$blackOpacity55,
  },
  notesContainer: {
    marginVertical: 24,
  },
  input: {
    paddingVertical: 7,
    alignContent: 'flex-start',
    height: 136,
  },
  header: {
    alignItems: 'center',
    marginTop: -16,
    marginBottom: 34,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: -10,
  },
  offerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    gap: 12,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: tokens.colors.$black10,
    height: 48,
    width: '100%',
    marginBottom: 20,
  },
  copy: {
    paddingTop: 12,
    paddingBottom: 24,
  },
  buttonGroup: {
    gap: 8,
  },
  container: {
    width: '100%',
    padding: 16,
  },
});
